/**
 * Created by Karol Kluska on 29.05.17.
 */

angular
    .module('app')
    .factory('restService', restService);

/** @ngInject */
function restService($rootScope, $http) {
  return {
    request: request
  };

  function request(params) {
    $rootScope.$broadcast('test');

    var defaults = {
      url: '',
      method: '',
      params: {},
      headers: {
        'Content-Type': 'application/json'
      }
    };

    var options = angular.extend(defaults, params);
    var bodyRequest = options.params;

    if (options.headers['Content-Type'] && options.headers['Content-Type'].match('application/json') !== null) {
      bodyRequest = angular.toJson(options.params);
    }

    return $http({
      url: options.url,
      method: options.method,
      data: bodyRequest,
      headers: options.headers
    });
  }
}
