angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('graphic', {
      url: '/{user_id:[a-fA-F0-9]{32}}/{object_id:[0-9]}/{locale:[a-zA-Z]{2}}?columnCount&columnWidth&nameLimitChars',
      templateUrl: 'app/graphic/views/reservations.html',
      controller: 'ReservationController',
      controllerAs: 'ctrl',
      resolve: {
        rooms: function (GraphicService, $stateParams) {
          return GraphicService.getRooms($stateParams.user_id, $stateParams.object_id)
                    .then(function (response) {
                      return response.data;
                    });
        },
        userCss: function (GraphicService, $stateParams) {
          return GraphicService.getUserCss($stateParams.user_id, $stateParams.object_id)
            .then(function (response) {
              return response.data;
            });
        }
      }
    });
}
