/**
 * Created by Karol Kluska on 26.05.17.
 */

angular
    .module('app')
    .controller('ReservationController', reservationsController)
    .controller('ReservationGraphicController', reservationGraphicController);

/** @ngInject */
function reservationsController(rooms, userCss, GraphicService, $loading, $stateParams) {
  var vm = this;

  vm.startDate = moment().startOf('day');
  vm.userCss = userCss;
  vm.rooms = rooms;
  vm.reservations = [];
  vm.restrictions = [];
  vm.periodChange = periodChange;

  GraphicService.getRestrictions($stateParams.user_id)
      .then(function (response) {
        vm.restrictions = response.data.resgraphicInfo.periods;
      });

  function periodChange(from, to) {
    $loading.start('loading');
    GraphicService.getReservations(moment(from).subtract(1, 'months'), moment(to).add(1, 'months'), $stateParams.user_id)
        .then(function (response) {
          vm.reservations = response.data;
          $loading.finish('loading');
        });
  }
}

/** @ngInject */ // eslint-disable-next-line
function reservationGraphicController($uibModal, Notification, $translate, envService, $stateParams) {
  var vm = this;
  vm.rows = [];
  vm.columns = [];
  vm.markers = [];
  vm.selections = [];
  vm.sameMonth = [];
  vm.sameYear = [];
  vm.roomReservations = [];
  vm.reservationRestrictions = [];
  vm.columnCount = 0;
  vm.columnWidth = getColumnWidth();
  vm.fixedColumnCount = $stateParams.columnCount;
  vm.nameLimitChars = $stateParams.nameLimitChars;
  vm.columnsMarked = 0;
  vm.markedNights = 0;
  vm.markerWidth = 0;
  vm.todayDate = moment().startOf('day');
  vm.datepickerOptions = {
    singleDatePicker: true,
    locale: {
      firstDay: 1,
      daysOfWeek: [
        $translate.instant('weekdays_short.SUN'),
        $translate.instant('weekdays_short.MON'),
        $translate.instant('weekdays_short.TUE'),
        $translate.instant('weekdays_short.WED'),
        $translate.instant('weekdays_short.THU'),
        $translate.instant('weekdays_short.FRI'),
        $translate.instant('weekdays_short.SAT')
      ],
      monthNames: [
        $translate.instant('months_short.JAN'),
        $translate.instant('months_short.FEB'),
        $translate.instant('months_short.MAR'),
        $translate.instant('months_short.APR'),
        $translate.instant('months_short.MAY'),
        $translate.instant('months_short.JUN'),
        $translate.instant('months_short.JUL'),
        $translate.instant('months_short.AUG'),
        $translate.instant('months_short.SEP'),
        $translate.instant('months_short.OCT'),
        $translate.instant('months_short.NOV'),
        $translate.instant('months_short.DEC')
      ]
    }
  };
  vm.getDayName = getDayName;
  vm.getMonthName = getMonthName;
  vm.updateColumns = updateColumns;
  vm.triggerPeriodChange = triggerPeriodChange;
  vm.getEndDate = getEndDate;
  vm.resetMarker = resetMarker;
  vm.deleteMarkers = deleteMarkers;
  vm.prevDays = prevDays;
  vm.prevAll = prevAll;
  vm.nextDays = nextDays;
  vm.nextAll = nextAll;
  vm.today = today;
  vm.markerClick = markerClick;
  vm.showMessage = showMessage;

  function getMonthName(month) {
    var monthIndex = moment(month).month();
    var monthList = [
      $translate.instant('months_short.JAN'),
      $translate.instant('months_short.FEB'),
      $translate.instant('months_short.MAR'),
      $translate.instant('months_short.APR'),
      $translate.instant('months_short.MAY'),
      $translate.instant('months_short.JUN'),
      $translate.instant('months_short.JUL'),
      $translate.instant('months_short.AUG'),
      $translate.instant('months_short.SEP'),
      $translate.instant('months_short.OCT'),
      $translate.instant('months_short.NOV'),
      $translate.instant('months_short.DEC')
    ];
    return monthList[monthIndex];
  }

  function getDayName(day) {
    var dayIndex = moment(day).day();
    var dayList = [
      $translate.instant('weekdays_short.SUN'),
      $translate.instant('weekdays_short.MON'),
      $translate.instant('weekdays_short.TUE'),
      $translate.instant('weekdays_short.WED'),
      $translate.instant('weekdays_short.THU'),
      $translate.instant('weekdays_short.FRI'),
      $translate.instant('weekdays_short.SAT')
    ];
    return dayList[dayIndex];
  }

  function getColumnWidth() {
    var columnWidth = Number($stateParams.columnWidth);
    if (columnWidth > 25) {
      return columnWidth;
    }
    return 100;
  }

  if (angular.isUndefined(vm.startDate)) {
    today();
  }

  function today() {
    vm.startDate = moment().startOf('day');
  }

  function prevDays() {
    var isBeforeToday = moment(vm.startDate).subtract(Math.round(vm.columnCount / 3), 'days').isBefore(moment());
    if (isBeforeToday) {
      today();
    } else {
      vm.startDate = moment(vm.startDate).subtract(Math.round(vm.columnCount / 3), 'days');
    }
  }

  function nextDays() {
    vm.startDate = moment(vm.startDate).add(Math.round(vm.columnCount / 3), 'days');
  }

  function prevAll() {
    var isBeforeToday = moment(vm.startDate).subtract(Math.round(vm.columnCount / 3), 'days').isBefore(moment());
    if (isBeforeToday) {
      today();
    } else {
      vm.startDate = moment(vm.startDate).subtract(vm.columnCount, 'days');
    }
  }

  function nextAll() {
    vm.startDate = moment(vm.startDate).add(vm.columnCount, 'days');
  }

  function updateColumns() {
    var date;
    var isPast;
    var isToday;
    var isWeekend;
    var startDate = vm.startDate;
    var columns = vm.columns;
    var today = moment();
    for (var i = 0; i < columns.length; i++) {
      date = moment(startDate).add(i, 'days');
      isPast = moment(date).isBefore(today, 'day');
      isToday = moment(date).isSame(today, 'day');
      isWeekend = moment(date).day() === 6 || moment(date).day() === 0;
      columns[i].past = isPast;
      columns[i].today = isToday;
      columns[i].weekend = !isToday && !isPast && isWeekend;
      columns[i].weekday = !isToday && !isPast && !isWeekend;
      columns[i].date = date.format('YYYY-MM-DD');
    }
  }

  function triggerPeriodChange(soft) {
    if (soft) {
      vm.resetMarker();
    } else {
      vm.periodChange({
        $from: vm.startDate,
        $to: getEndDate()
      });
    }
  }

  function resetMarker() {
    vm.selectedColumn = 0;
    vm.columnsMarked = 0;
    vm.markedNights = 0;
    vm.markerElement = null;
    vm.markerVisible = false;
  }

  function deleteMarkers() {
    vm.markers.length = 0;
  }

  function markerClick() {
    var url =
            envService.read('bookingEngineUrl') +
            $stateParams.locale + '/' +
            $stateParams.user_id + '/' +
            $stateParams.object_id + '?' +
            'checkIn=' + moment(vm.markers[0].start).format('YYYY-MM-DD') + '&' +
            'checkOut=' + moment(vm.markers[0].end).format('YYYY-MM-DD') + '&' +
            'roomGroupId=' + vm.markers[0].groupId;
    var modalInstance = $uibModal.open({
      animation: true,
      size: 'lg',
      controller: function (url) {
        var vm = this;
        vm.url = url;
      },
      controllerAs: 'ctrl',
      templateUrl: 'app/graphic/views/booking-engine-modal.html',
      resolve: {
        url: function () {
          return url;
        }
      }
    });
    modalInstance.result.then(function () {
      vm.deleteMarkers();
      vm.triggerPeriodChange();
    }, function () {
      vm.deleteMarkers();
      vm.triggerPeriodChange();
    });
  }

  function getEndDate() {
    return moment(vm.startDate).add(vm.columnCount - 1, 'days');
  }

  function showMessage(message, variable, suffix) {
    Notification.error({
      message: $translate.instant(message) + ' ' + variable + ' ' + $translate.instant(suffix)
    });
  }
}
