/**
 * Created by Karol Kluska on 03.10.17.
 */

angular
  .module('app')
  .config(languagesConfig);

/** @ngInject */
function languagesConfig($translateProvider) {
  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.useStaticFilesLoader({
    prefix: 'i18n/locale-',
    suffix: '.json'
  });
  $translateProvider
    .determinePreferredLanguage(function () {
      var availableLanguages = ['de', 'en', 'fr', 'gr', 'it', 'lv', 'no', 'pl', 'ru', 'ua'];
      var defaultLangKey = 'en';
      var pathArray = window.location.pathname.split('/'); // eslint-disable-line
      var languageKey = pathArray[3];

      if (availableLanguages.indexOf(languageKey) !== -1) {
        return languageKey;
      }
      return defaultLangKey;
    });
}
