angular
  .module('app', [
    'ui.router',
    'ui.bootstrap',
    'ui-notification',
    'angularMoment',
    'daterangepicker',
    'environment',
    'pascalprecht.translate',
    'darthwade.dwLoading'
  ])
  .config(function ($sceProvider) {
    $sceProvider.enabled(false);
  })
  .run(run);

/** @ngInject */
function run($stateParams, $translate) {
  $translate.use($stateParams.locale);
  moment.locale($stateParams.locale);
}
