/**
 * Created by Karol Kluska on 26.05.17.
 */

angular
  .module('app')
  .directive('reservationGraphic', reservationGraphic)
  .directive('reservationGraphicGrid', reservationGraphicGrid)
  .directive('reservationGraphicHeader', reservationGraphicHeader)
  .directive('reservationGraphicRooms', reservationGraphicRooms)
  .directive('reservationGraphicColumn', reservationGraphicColumn)
  .directive('reservationGraphicReservations', reservationGraphicReservations)
  .directive('reservationGraphicMarkers', reservationGraphicMarkers)
  .directive('fixedNavigation', fixedNavigation)
  .directive('fixedDates', fixedDates)
  .directive('stylesheet', stylesheet)
  .component('reservationGraphicRoom', {
    templateUrl: 'app/graphic/views/reservations-room.html',
    bindings: {
      roomId: '@',
      groupId: '@',
      symbol: '@',
      beds: '@',
      name: '@',
      limit: '@'
    }
  });

function reservationGraphic() {
  return {
    restrict: 'AE',
    scope: {},
    controller: 'ReservationGraphicController',
    controllerAs: 'ctrl',
    bindToController: {
      startDate: '=',
      rooms: '=',
      groups: '=',
      reservations: '=',
      periodChange: '&'
    },
    templateUrl: 'app/graphic/views/reservations-graphic.html',
    link: function (scope, elem, attrs, ctrl) {
      function parseRooms(rooms) {
        var result = [];
        for (var i = 0; i < rooms.length; i++) {
          var room = rooms[i];
          result.push({
            name: room.name,
            symbol: room.name,
            roomId: parseInt(room.id, 10),
            groupId: parseInt(room.room_group.id, 10),
            beds: room.room_group.beds_amount
          });
        }
        return result;
      }

      function parseReservations(reservations) {
        var result = [];
        for (var i = 0; i < reservations.length; i++) {
          var reservation = reservations[i];
          result.push({
            name: '',
            reservationId: 0,
            roomId: parseInt(reservation.room_id, 10),
            start: moment(reservation.date_s).format('YYYY-MM-DD'),
            end: moment(reservation.date_e).add(1, 'days').format('YYYY-MM-DD'),
            status: {
              color: 'FF6666'
            }
          });
        }
        return result;
      }

      function parseReservationRestrictions(restrictions) {
        var result = [];
        for (var i = 0; i < restrictions.length; i++) {
          var restriction = restrictions[i];
          if (restriction.rgp_available === '1') {
            result.push({
              start: moment(restriction.rgp_date_s).format('YYYY-MM-DD'),
              end: moment(restriction.rgp_date_e).format('YYYY-MM-DD'),
              startInDays: getAvailableDays(restriction.rgp_date_s_weekday),
              minGap: parseInt(restriction.rgp_gap, 10),
              minDays: parseInt(restriction.rgp_mindays, 10),
              maxDays: parseInt(restriction.rgp_maxdays, 10)
            });
          }
        }
        return result;

        function getAvailableDays(decimal) {
          decimal = (decimal === '-1') ? 127 : parseInt(decimal, 10);
          var binaryAsDaysBackward = decimalToBinary(decimal);
          var binaryAsDaysFromMonday = binaryAsDaysBackward.split('').reverse();
          var weekdayCodesFromMonday = [1, 2, 3, 4, 5, 6, 0];
          var result = [];
          for (var i = 0; i < binaryAsDaysFromMonday.length; i++) {
            var day = binaryAsDaysFromMonday[i];
            if (day === '1') {
              result.push(weekdayCodesFromMonday[i]);
            }
          }
          return result;
        }

        function decimalToBinary(decimal) {
          return (decimal >>> 0).toString(2);
        }
      }

      scope.$watchGroup(['ctrl.startDate', 'ctrl.columnCount', 'ctrl.columnWidth'], function () {
        ctrl.updateColumns();
        if (ctrl.columnCount) {
          ctrl.triggerPeriodChange();
        }
      });
      scope.$watch('ctrl.rooms', function (rooms) {
        ctrl.rows = parseRooms(rooms || []);
      });
      scope.$watch('ctrl.reservations', function (reservations) {
        ctrl.roomReservations = parseReservations(reservations);
      });
      scope.$watch('ctrl.restrictions', function (restrictions) {
        ctrl.reservationRestrictions = parseReservationRestrictions(restrictions || []);
      });
      elem.on('contextmenu', function (evt) {
        evt.preventDefault();
        return false;
      });
    }
  };
}

/** @ngInject */
function reservationGraphicGrid($window, $timeout) {
  var element = angular.element;
  return {
    restrict: 'AE',
    require: '^reservationGraphic',
    link: function (scope, elem, attrs, ctrl) {
      var marker;
      var DIV_MARKER_INDEX = 0;
      var selection;
      var mouseX;
      var win = element($window);
      var columnsElem = elem.children().eq(1).children();
      var markers = ctrl.markers;

      function init() {
        calculate();
        scope.$watch('ctrl.columnCount', function (count) {
          updateColumns(count);
        });
        scope.$watchGroup(['ctrl.columnCount', 'ctrl.columnWidth'], function () {
          updateColumns(ctrl.columnCount);
        });
        marker = columnsElem.children().eq(DIV_MARKER_INDEX);
        columnsElem.on('mousedown', handleClick);
        scope.$watch('ctrl.startDate', checkSelection);
        scope.$on('$destroy', function () {
          win.off('mousemove', ctrl.resetMarker());
        });
      }

      function calculate() {
        var count = Math.floor(columnsElem.prop('offsetWidth') / ctrl.columnWidth);
        var fixedColumnCount = Number(ctrl.fixedColumnCount);

        if (fixedColumnCount > 0 && fixedColumnCount < 50) {
          count = fixedColumnCount;
        }
        if (count < 5) {
          count = 5;
        }
        var columns = ctrl.columns;
        while (columns.length !== count) {
          if (columns.length > count) {
            columns.pop();
          } else {
            columns.push({});
          }
        }
        columnsElem.css({
          width: (ctrl.columnWidth * count) + 'px'
        });
        ctrl.columnCount = count;
        ctrl.updateColumns();
      }

      function updateColumns(count) {
        var columns = ctrl.columns;
        while (columns.length !== count) {
          if (columns.length > count) {
            columns.pop();
          } else {
            columns.push({});
          }
        }
        ctrl.columnCount = count;
        columnsElem.css({
          width: (ctrl.columnWidth * count) + 'px'
        });
        columnsElem.scrollLeft = 0;
        ctrl.updateColumns();
      }

      function handleClick(evt) {
        var id;
        var bounds;
        var isPermitted;
        var markingOtherUnit;
        var markingOutOfBounds;
        var markingAvailable;
        var target = element(evt.target);
        var column = target.parent().parent();
        var date = moment(column.attr('id'));
        var isCell = target.hasClass('reservation-graphic-day');
        if (isCell && evt.button === 0) {
          id = target.attr('id');
          bounds = findSelectionBounds(id, moment(date));
          isPermitted = checkRestrictions(moment(date), id);
          markingAvailable = !(!bounds.from || !bounds.to);
          markingOtherUnit = (selection && selection.id !== id);
          markingOutOfBounds = (selection && selection.to && moment(selection.to).format('YYYY-MM-DD') !== column.attr('id'));

          if (!markingAvailable || selection || ctrl.markerElement || !isPermitted) {
            if (!markingAvailable || markingOtherUnit || markingOutOfBounds || !isPermitted) {
              endMarking();
            } else {
              endMarking(date);
            }
          } else {
            var start = moment(date).format('YYYY-MM-DD');
            startMarking(start, target, bounds);
          }
        }
      }

      function checkRestrictions(date, id) {
        var targetRestriction = null;
        var i;
        for (i = 0; i < ctrl.reservationRestrictions.length; i++) {
          var restriction = ctrl.reservationRestrictions[i];
          var isApplicable = moment(date).isBetween(restriction.start, restriction.end, 'days', '[]');
          if (isApplicable) {
            targetRestriction = ctrl.reservationRestrictions[i];
            break;
          }
        }

        if (targetRestriction) {
          var bounds = findSelectionBounds(id, date, true);
          var gapLeft = moment(date).diff(moment(bounds.from), 'days');
          var gapRight = moment(bounds.to).diff(moment(date), 'days');
          var gapLength = gapLeft + gapRight;
          var isGap = gapLength < targetRestriction.minDays + (targetRestriction.minGap * 2);

          var from;
          var to;
          var selectedDays;
          if (!isGap) {
            if (gapLeft < targetRestriction.minGap || gapRight < targetRestriction.minGap) {
              ctrl.showMessage('MIN_SPACE_BETWEEN_RESERVATIONS', targetRestriction.minGap, 'DAYS');
              return false;
            }
            if (selection) {
              targetRestriction.maxDays = (targetRestriction.maxDays === 0) ? 999 : targetRestriction.maxDays;
              from = moment.min(moment(date), moment(selection.from));
              to = moment.max(moment(date), moment(selection.from));
              selectedDays = moment(to).diff(moment(from), 'days');
              if (selectedDays < targetRestriction.minDays || selectedDays > targetRestriction.maxDays) {
                ctrl.showMessage('MIN_RESERVATION_PERIOD', targetRestriction.minDays, 'DAYS');
                return false;
              }
            } else {
              var startInAvailableDay = targetRestriction.startInDays.indexOf(moment(date).day());
              if (startInAvailableDay === -1) {
                var availableDays = [];
                for (i = 0; i < targetRestriction.startInDays.length; i++) {
                  availableDays.push(moment.weekdays(targetRestriction.startInDays[i]));
                }
                ctrl.showMessage('RESERVATION_CAN_START_IN_DAYS', availableDays, '');
                return false;
              }
            }
          } else if (gapLength > targetRestriction.minDays) {
            if (selection) {
              targetRestriction.maxDays = (targetRestriction.maxDays === 0) ? 999 : targetRestriction.maxDays;
              from = moment.min(moment(date), moment(selection.from));
              to = moment.max(moment(date), moment(selection.from));
              selectedDays = moment(to).diff(moment(from), 'days');
              if (selectedDays < targetRestriction.minDays || selectedDays > targetRestriction.maxDays) {
                ctrl.showMessage('MIN_RESERVATION_PERIOD', targetRestriction.minDays, 'DAYS');
                return false;
              }
            }
          }
        }
        return true;
      }

      function checkSelection() {
        if (selection) {
          ctrl.markerVisible = true;
          handleMouseMove({
            pageX: mouseX
          });
        } else {
          ctrl.resetMarker();
        }
      }

      function startMarking(date, target, bounds) {
        win.off('mousemove', handleMouseMove);
        selection = {
          from: date,
          id: target.attr('id'),
          groupId: target.attr('data-group-id'),
          bounds: bounds
        };
        updateSelectionBounds();
        marker.css({
          top: target.prop('offsetTop') + 20 + 'px',
          height: target.prop('offsetHeight') + 'px'
        });
        scope.$apply(function () {
          ctrl.selectedColumn = findColumnByDate(date);
          ctrl.columnsMarked = 1;
          ctrl.markedNights = 0;
          ctrl.markerElement = null;
          ctrl.markerVisible = true;
        });
        win.on('mousemove', handleMouseMove);
      }

      function endMarking(date) {
        win.off('mousemove', handleMouseMove);
        scope.$apply(function () {
          if (selection && date && !(moment(selection.from).isSame(moment(date)))) {
            var roomId = parseInt(selection.id, 10);
            var groupId = parseInt(selection.groupId, 10);

            ctrl.markerElement = marker;
            ctrl.markedEntity = {
              id: roomId,
              period: Math.abs(moment(selection.from).diff(moment(date), 'days'))
            };

            var checkIn = moment.min(moment(date), moment(selection.from));
            var checkOut = moment.max(moment(date), moment(selection.from));
            var sig = checkIn + checkOut + roomId;
            markers.push({
              id: sig,
              roomId: roomId,
              groupId: groupId,
              start: checkIn,
              end: checkOut
            });
            ctrl.markerClick();
          } else {
            ctrl.resetMarker();
          }
        });
        ctrl.resetMarker();
        selection = null;
        mouseX = null;
      }

      function handleMouseMove(evt) {
        scope.$evalAsync(function () {
          var boundsFrom;
          var boundsTo;
          var currBounds = selection.bounds;
          var bounds = findSelectionBounds(selection.id, moment(selection.from).format('YYYY-MM-DD'));
          if (!currBounds.lockedFrom && moment(currBounds.from).isAfter(moment(bounds.from))) {
            currBounds.from = moment(bounds.from);
          }
          if (!currBounds.lockedTo && moment(bounds.to).isAfter(moment(currBounds.to))) {
            currBounds.to = moment(bounds.to);
          }
          updateSelectionBounds();
          boundsFrom = moment.max(moment(currBounds.from), moment(bounds.from));
          boundsTo = moment.min(moment(currBounds.to), moment(bounds.to));
          var from = moment(moment.max(moment(ctrl.startDate), moment.min(moment(selection.from), moment(ctrl.getEndDate()))));
          var column = $window.document.querySelector('[id="' + moment(from).format('YYYY-MM-DD') + '"]');
          var cell = column.querySelector('[id="' + selection.id + '"]');
          var marked = getMarkedColumnsCount(cell, evt.pageX);
          var markedDate = moment(from).add(marked, 'days');
          var to = moment.max(moment(boundsFrom), moment.min(moment(markedDate), moment(boundsTo)));
          var markedNights = moment(to).diff(moment(selection.from), 'days');
          ctrl.markedNights = Math.abs(markedNights);
          if (moment(from).isAfter(moment(boundsFrom)) || moment(boundsTo).isAfter(from)) {
            ctrl.selectedColumn = findColumnByDate(moment(moment.min(moment(from), moment(to))));
            ctrl.columnsMarked = Math.abs(moment(to).diff(moment(from), 'days')) + 1;
            selection.to = moment(to).format('YYYY-MM-DD');
            mouseX = evt.page;
          }
        });
      }

      function findColumnByDate(date) {
        var column = moment(date).diff(moment(ctrl.startDate), 'days');
        return Math.min(Math.max(0, column), ctrl.columnCount + 1);
      }

      function findSelectionBounds(id, date, hasRestrictions) {
        var startDate = (hasRestrictions) ? moment(ctrl.startDate).subtract(3, 'months') : moment(ctrl.startDate);
        var endDate = (hasRestrictions) ? moment(ctrl.getEndDate()).add(3, 'months') : moment(ctrl.getEndDate());
        var busy = getBusyPeriods(id);
        var fromMin = busy[0] || startDate;
        var toMin = busy[busy.length - 1] || endDate;
        var from = moment.min(moment(startDate), moment(fromMin));
        var to = moment.max(moment(endDate), moment(toMin));
        var checkIn;
        var checkOut;

        date = moment.max(moment.min(moment(date), moment(to)), moment(from));

        for (var i = 0; i < busy.length; i += 2) {
          checkIn = moment(busy[i]);
          checkOut = moment(busy[i + 1]);
          if (moment(date).isAfter(moment(checkIn)) && moment(checkOut).isAfter(moment(date))) {
            return {};
          }
          if (moment(date).isSameOrAfter(moment(checkOut)) && moment(checkOut).isSameOrAfter(moment(from))) {
            from = moment(checkOut);
          }
          if (moment(checkIn).isSameOrAfter(moment(date)) && moment(to).isSameOrAfter(moment(checkIn))) {
            to = moment(checkIn);
          }
        }
        return {
          from: from,
          to: to
        };
      }

      function getBusyPeriods(id) {
        var busy = ctrl.roomReservations.concat(ctrl.markers);
        return (busy || []).filter(function (event) {
          return event.roomId === id;
        }).sort(function (a, b) {
          return a.start - b.end;
        }).map(function (obj) {
          return [obj.start, obj.end];
        }).reduce(function (previous, current) {
          return previous[previous.length - 1] === current[0] ? previous.slice(0, previous.length - 1).concat([current[1]]) : previous.concat(current);
        }, []);
      }

      function getMarkedColumnsCount(cell, mouseX) {
        var rect = cell.getBoundingClientRect();
        var distance = getDistanceToRect(rect, mouseX);
        return sign(distance) * Math.ceil(Math.abs(distance) / Math.ceil(rect.width));
      }

      function getDistanceToRect(rect, mouseX) {
        var half = Math.ceil(0.5 * rect.width);
        var toCenter = mouseX - Math.ceil(rect.left) - half;
        return sign(toCenter) * Math.max(Math.abs(toCenter) - half, 0);
      }

      function sign(nr) {
        return nr > 0 ? 1 : nr < 0 ? -1 : 0;
      }

      function updateSelectionBounds() {
        var bounds = selection.bounds;
        if (bounds && moment(bounds.from).isAfter(moment(ctrl.startDate))) {
          bounds.lockedFrom = true;
        }
        if (bounds && moment(ctrl.getEndDate()).isAfter(moment(bounds.to))) {
          bounds.lockedTo = true;
        }
      }

      $timeout(init);
    }
  };
}

/** @ngInject */
function reservationGraphicHeader($timeout) {
  return {
    restrict: 'AE',
    require: '^reservationGraphic',
    link: function (scope, elem, attrs, ctrl) {
      function renderHeader(columns) {
        elem.html('');
        if (columns) {
          $timeout(function () {
            var el;
            var firstDate = moment(columns[0].date);
            var lastDate = moment(columns[ctrl.columnCount - 1].date);

            var start = firstDate.month() + (firstDate.year() * 12);
            var end = lastDate.month() + (lastDate.year() * 12);

            for (var i = start; i <= end; i++) {
              var count = 0;
              var date;
              var dateMonth;
              var last;

              for (var j = 0; j < columns.length; j++) {
                date = moment(columns[j].date);
                dateMonth = date.month() + (date.year() * 12);

                if (dateMonth === i) {
                  count++;
                  last = moment(columns[j].date);
                }
              }

              el = createHeaderElement(count, ctrl.getMonthName(last) + ' ' + last.format('YYYY'));
              elem.append(el);
            }
          });
        }
      }

      function createHeaderElement(columns, text) {
        var width = columns * ctrl.columnWidth;
        var el = angular.element('<div class="reservation-graphic-item">' + text + '</div>');
        el.css({width: width + 'px'});
        return el;
      }

      scope.$watchGroup(['ctrl.startDate', 'ctrl.columnCount', 'ctrl.columnWidth'], function () {
        if (ctrl.columns.length) {
          renderHeader(ctrl.columns);
        }
      });
    }
  };
}

/** @ngInject */
function reservationGraphicRooms($compile) {
  return {
    restrict: 'AE',
    require: '^reservationGraphic',
    link: function (scope, elem, attrs, ctrl) {
      scope.$watch(function () {
        return ctrl.rows;
      }, function (rows) {
        for (var i = rows.length - 1; i >= 0; i--) {
          var item = angular.element('<reservation-graphic-room></reservation-graphic-room>');
          var row = rows[i];
          item.attr({
            roomId: row.roomId,
            groupId: row.groupId,
            symbol: row.symbol,
            beds: row.beds,
            name: row.name,
            limit: ctrl.nameLimitChars || 8
          });
          elem.prepend($compile(item)(scope));
        }
      });
    }
  };
}

/** @ngInject */
function fixedNavigation($window) {
  return {
    restrict: 'AE',
    require: '^reservationGraphic',
    link: function (scope, elem) {
      var gridContainerElem = elem.parent();

      elem.css({
        top: 0,
        'z-index': 5
      });

      angular.element($window).bind('scroll', function () {
        if (this.pageYOffset <= 0) {
          elem.css({
            top: (-this.pageYOffset) + 'px'
          });
        }
        scope.$apply();
      });

      scope.$watch(function () {
        return gridContainerElem.width();
      }, function (parentWidth) {
        elem.css({
          'max-width': parentWidth + 'px'
        });
      });
    }
  };
}

/** @ngInject */
function fixedDates($window) {
  return {
    restrict: 'AE',
    require: '^reservationGraphic',
    link: function (scope, elem, attrs, ctrl) {
      var gridContainerElem = elem.parent();
      var dateColumnsElem = elem.children();

      elem.css({
        top: 34,
        'z-index': 5
      });

      angular.element($window).bind('scroll', function () {
        if (this.pageYOffset <= 0) {
          elem.css({
            top: (-this.pageYOffset) + 34 + 'px'
          });
        }
        scope.$apply();
      });

      scope.$watch(function () {
        return gridContainerElem.offset().left;
      }, function (offsetLeft) {
        elem.css({
          'margin-left': offsetLeft + 'px'
        });
      });

      scope.$watch(function () {
        return gridContainerElem.width();
      }, function (parentWidth) {
        elem.css({
          'max-width': parentWidth + 'px'
        });
      });

      scope.$watchGroup(['ctrl.columnCount', 'ctrl.columnWidth'], function () {
        dateColumnsElem.css({
          width: (ctrl.columnWidth * ctrl.columnCount) + 'px'
        });
      });

      gridContainerElem.on('scroll', function () {
        dateColumnsElem.css({
          left: '-' + gridContainerElem.scrollLeft() + 'px'
        });
      });
    }
  };
}

function reservationGraphicColumn() {
  return {
    restrict: 'AE',
    scope: true,
    require: '^reservationGraphic',
    link: function (scope, elem) {
      scope.$watch('ctrl.rows', function (rows) {
        var cell;
        rows = (rows || []).slice(0).reverse();
        angular.forEach(rows, function (row) {
          cell = angular.element('<div class="reservation-graphic-grid-cell"><div id="' + row.roomId + '" data-group-id="' + row.groupId + '" class="reservation-graphic-cell reservation-graphic-day clearfix"><div class="reservation-graphic-day-hover"></div></div></div>');
          elem.prepend(cell);
        });
      });
    }
  };
}

/** @ngInject */
function reservationGraphicReservations($timeout) {
  return {
    restrict: 'AE',
    scope: true,
    controllerAs: 'reservationsCtrl',
    controller: function () {},
    bindToController: {
      eventsRendered: '&'
    },
    require: '^reservationGraphic',
    link: function (scope, elem, attrs, ctrl) {
      var element = angular.element;
      var reservationsRendered = [];

      function renderEvents(events) {
        var eventElements = [];
        elem.html('');
        reservationsRendered = [];
        if (events) {
          $timeout(function () {
            var event;
            var dimensions;
            var el;
            for (var i = 0; i < events.length; i++) {
              event = events[i];
              dimensions = getDimensions(event);
              if (dimensions) {
                el = createEvent(event, dimensions);
                elem.prepend(el);
                formatLabel(event.name, el.children().eq(0));
                eventElements.push(el);
                reservationsRendered.push(event);
              }
            }
          });
        }
      }

      function createEvent(event, dimensions) {
        var id = event.reservationId;
        var classes = [dimensions.endOutOfBounds ? 'radius-left' : '', dimensions.startOutOfBounds ? 'radius-right' : ''].join(' ');
        var el = element('<div class="reservation-graphic-reservation-container"><div  id="' + id + '" class="reservation-graphic-reservation clearfix ' + classes + ' "><span></span></div></div>');
        var backgroundColor = event.status.color || 'FF0000';
        var textColor = getColorContrast(backgroundColor);

        el.css({
          top: (dimensions.top + 4) + 'px',
          left: dimensions.left + 'px',
          width: dimensions.width + 'px',
          height: dimensions.height + 'px',
          'z-index': dimensions.zIndex
        });
        el.children().css({
          'background-color': '#' + backgroundColor,
          color: '#' + textColor,
          height: dimensions.height + 'px',
          'z-index': dimensions.zIndex
        });
        return el;
      }

      function formatLabel(customer, el) {
        var labelElem = el.find('span');
        labelElem = labelElem.eq(labelElem.length - 1);
        if (customer && labelElem.length) {
          labelElem.text(customer);
        }
      }

      function getColorContrast(hexcolor) {
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? '333333' : 'ffffff';
      }

      function getDimensions(event) {
        var from;
        var to;
        var half;
        var startPiece;
        var endPiece;
        var startDate = ctrl.startDate;
        var endDate = moment(ctrl.startDate).add(ctrl.columnCount - 1, 'days');
        var checkIn = event.start;
        var checkOut = event.end;
        var rowIndex = findRowIndex(event.roomId);
        var columnWidth = ctrl.columnWidth;
        var overbookingCount = findEventsCountInDates(event.roomId, checkIn, checkOut);

        if (moment(checkOut).diff(moment(startDate), 'days') < 0 || moment(endDate).diff(moment(checkIn), 'days') < 0 || rowIndex === -1) {
          return null;
        }
        from = moment.max(moment(checkIn), moment(startDate));
        to = moment.min(moment(checkOut), moment(endDate));
        half = 0.5 * columnWidth;
        startPiece = moment(from).isSame(moment(checkIn)) ? 0 : half;
        endPiece = moment(to).isSame(moment(checkOut)) ? 0 : half;
        return {
          top: (40 * rowIndex) + 20,
          left: (findColumnIndex(from) * columnWidth) + (startPiece ? 0 : half),
          width: startPiece + (Math.floor(moment(to).diff(from, 'days')) * columnWidth) + endPiece,
          height: divideByTwo(32, overbookingCount),
          zIndex: overbookingCount,
          startOutOfBounds: Boolean(startPiece),
          endOutOfBounds: Boolean(endPiece)
        };

        function divideByTwo(number, repeatCount) {
          return repeatCount === 0 ? number : divideByTwo(number / 2, --repeatCount);
        }
      }

      function findRowIndex(roomId) {
        return ctrl.rows.map(function (row) {
          return row.roomId;
        }).indexOf(roomId);
      }

      function findColumnIndex(date) {
        return Math.floor(moment(date).diff(ctrl.startDate, 'days'));
      }

      function findEventsCountInDates(roomId, checkIn, checkOut) {
        var count = 0;

        for (var i = 0; i < reservationsRendered.length; i++) {
          var reservation = reservationsRendered[i];
          if (roomId === reservation.roomId) {
            if (moment(checkIn).isBetween(moment(reservation.start), moment(reservation.end), 'days', '[)') ||
                            moment(checkOut).isBetween(moment(reservation.start), moment(reservation.end), 'days', '[)')
                        ) {
              count++;
            }
          }
        }

        return count;
      }

      scope.$watch('ctrl.roomReservations', function (reservations) {
        renderEvents(reservations);
      });
    }
  };
}

/** @ngInject */
function reservationGraphicMarkers($timeout) {
  return {
    restrict: 'AE',
    scope: true,
    require: '^reservationGraphic',
    link: function (scope, elem, attrs, ctrl) {
      var element = angular.element;

      function renderMarkers(markers) {
        elem.html('');
        if (markers) {
          $timeout(function () {
            var marker;
            var dimensions;
            var el;
            for (var i = 0; i < markers.length; i++) {
              marker = markers[i];
              dimensions = getDimensions(marker);
              if (dimensions) {
                el = createMarker(marker, dimensions);
                elem.prepend(el);
              }
            }
          });
        }
      }
      function createMarker(marker, dimensions) {
        var days = moment(marker.end).diff(moment(marker.start), 'days');
        var el = element('<div class="marker" id="' + marker.id + '"><div class="clearfix"><span class="nights"><b> ' + days + '</b></span></div></div>');
        el.css({
          height: '40px',
          top: dimensions.top + 'px',
          left: dimensions.left + 'px',
          width: dimensions.width + 'px'
        });
        return el;
      }

      function getDimensions(marker) {
        var from;
        var to;
        var half;
        var startPiece;
        var endPiece;
        var startDate = ctrl.startDate;
        var endDate = moment(ctrl.startDate).add(ctrl.columnCount - 1, 'days');
        var checkIn = marker.start;
        var checkOut = marker.end;
        var rowIndex = findRowIndex(marker.roomId);
        var columnWidth = ctrl.columnWidth;
        if (angular.isUndefined(checkOut) || moment(endDate).diff(moment(checkIn), 'days') < 0 || rowIndex === -1) {
          return null;
        }
        from = moment.max(moment(checkIn), moment(startDate));
        to = moment.min(moment(checkOut), moment(endDate));
        half = 0.5 * columnWidth;
        startPiece = moment(from).isSame(moment(checkIn)) ? 0 : half;
        endPiece = moment(to).isSame(moment(checkOut)) ? 0 : half;
        return {
          top: (40 * rowIndex) + 20,
          left: (findColumnIndex(from) * columnWidth) + (startPiece ? 0 : half),
          width: (startPiece + Math.floor(moment(to).diff(from, 'days'))) * (columnWidth + endPiece),
          startOutOfBounds: Boolean(startPiece),
          endOutOfBounds: Boolean(endPiece)
        };
      }

      function findMarkerElement(target, currentTarget) {
        target = element(target);
        currentTarget = element(currentTarget);
        while (!angular.equals(target, currentTarget)) {
          if (target.hasClass('marker')) {
            return target;
          }
          target = target.parent();
        }
      }

      function findRowIndex(roomId) {
        return ctrl.rows.map(function (row) {
          return row.roomId;
        }).indexOf(roomId);
      }

      function findColumnIndex(date) {
        return Math.floor(moment(date).diff(ctrl.startDate, 'days'));
      }

      scope.$watchGroup(['ctrl.startDate', 'ctrl.columnCount', 'ctrl.columnWidth', 'ctrl.markers.length'], function () {
        renderMarkers(ctrl.markers);
      });

      elem.on('contextmenu', function (evt) {
        var markerElem = findMarkerElement(evt.target, evt.currentTarget);
        if (markerElem) {
          scope.$apply(function () {
            evt.preventDefault();
            var markerId = parseInt(markerElem.attr('id'), 10);
            var markerPos = ctrl.markers.map(function (x) {
              return x.id;
            }).indexOf(markerId);
            ctrl.markers.splice(markerPos, 1);
          });
        }
      });

      elem.on('click', function () {
        scope.$apply(function () {
          ctrl.markerClick();
        });
      });
    }
  };
}

function stylesheet() {
  return {
    restrict: 'E',
    scope: {
      css: '=css'
    },
    link: function (scope) {
      var css = scope.css || '';
      var html = '<style type="text/css">' + css + '</style>';
      angular.element(html).appendTo(angular.element('head'));
    }
  };
}
