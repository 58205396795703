angular
  .module('app')
  .directive('iframeResizer', iframeResizer);

function iframeResizer() {
  return {
    restrict: 'A',
    link: function (scope, element) {
      iFrameResize({checkOrigin: false}, element[0]);
    }
  };
}
