/**
 * Created by Karol Kluska on 29.05.17.
 */

angular
    .module('app')
    .config(environment);

function environment(envServiceProvider) {
  envServiceProvider.config({
    domains: {
      development: ['localhost', 'reservation-graphic.kwhotel'],
      production: ['reservation-graphic.kwhotel.com', '*.reservation-graphic.kwhotel.com']
    },
    vars: {
      development: {
        apiUrl: 'http://web.kwhotel/app_dev.php/api/v2/open/',
        bookingEngineUrl: 'https://be-v2.kwhotel.com/'
      },
      production: {
        apiUrl: 'https://dev.kwhotel.com/api/v2/open/',
        bookingEngineUrl: 'https://be-v2.kwhotel.com/'
      },
      defaults: {
        apiUrl: 'http://web.kwhotel/app_dev.php/api/v2/open/',
        bookingEngineUrl: 'https://be-v2.kwhotel.com/'
      }
    }
  });
  envServiceProvider.check();
}
