/**
 * Created by Karol Kluska on 26.05.17.
 */

angular
    .module('app')
    .factory('GraphicService', reservationsService);

/** @ngInject */
function reservationsService(restService, envService) {
  var apiUrl = envService.read('apiUrl');

  /* eslint-disable camelcase */
  return {
    getReservations: function (from, to, userId) {
      return restService.request({
        url: apiUrl + 'occupancy',
        method: 'POST',
        params: {
          user_id: userId,
          date_s: from.format('YYYY-MM-DD'),
          date_e: to.format('YYYY-MM-DD')
        }
      });
    },

    getRooms: function (userId, objectId) {
      return restService.request({
        url: apiUrl + 'rooms',
        method: 'POST',
        params: {
          user_id: userId,
          onlineOff: true,
          hotel_id: objectId
        }
      });
    },

    getRestrictions: function (userId) {
      return restService.request({
        url: apiUrl + 'settings/get',
        method: 'POST',
        params: {
          user_id: userId,
          settings: [{
            '': ''
          }]
        }
      });
    },

    getUserCss: function (userId, objectId) {
      return restService.request({
        url: apiUrl + 'customer-css?user_id=' + userId + '&hotel_id=0',
        method: 'GET',
        params: {
          user_id: userId,
          hotel_id: parseInt(objectId, 10) === 0 ? null : objectId
        }
      });
    }
  };
  /* eslint-enable camelcase */
}
